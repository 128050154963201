<template>
  <router-view class="mx-auto" :class="{'container-lg px-1 py-4': !fullWidth}" />
</template>

<script>
export default {
  name: 'MainRouterView',
  computed: {
    fullWidth() {
      return (this.$route.meta && this.$route.meta.makeFullWidth);
    },
  },
};
</script>
